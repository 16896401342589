import { action, observable } from "mobx";
import { OptionsObject } from "notistack";
import { createContext } from "react";

export type VariantType = "default" | "error" | "success" | "warning" | "info";

class Notification {
  public key: number;
  public message: React.ReactNode;
  public options?: OptionsObject;

  constructor(message: React.ReactNode, options?: OptionsObject) {
    this.message = message;
    const key = new Date().getTime() + Math.random();
    this.options = {
      key,
    };
    this.key = key;
    this.options = {
      key,
      ...options,
    };
  }
}

export class NotificationStore {
  public readonly notifications = observable<Notification>([]);

  @action public removeSnackbar(key: number) {
    const index = this.notifications.findIndex(not => not.key === key);
    this.notifications.splice(index, 1);
  }

  @action public clear() {
    this.notifications.replace([]);
  }

  public enqueueAutohideSnackbar(message: React.ReactNode, variant: VariantType) {
    const notification = new Notification(message, { autoHideDuration: 1500, preventDuplicate: true, variant });
    notification.options!.onClose = () => {
      this.removeSnackbar(notification.key);
    };
    this.enqueueSnackbar(notification);
  }

  public enqueueActionSnackbar(message: React.ReactNode, variant: VariantType, action: OptionsObject["action"]) {
    const notification = new Notification(message, { persist: true, variant, action });
    this.enqueueSnackbar(notification);
  }

  @action private enqueueSnackbar(notification: Notification) {
    this.notifications.push(notification);
  }
}

const notificationStore = new NotificationStore();
export default createContext(notificationStore);
