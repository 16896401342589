import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { formatRoute } from "react-router-named-routes";
import Header from "../Header/SiteHeader";
import { API_KEYS_PATH, BILLING_PATH, MANAGE_ORGS_PATH, SETTINGS_PATH } from "../Routes";
import Link from "../UI/Link";
import { styles } from "./styles";

interface IProps extends WithStyles<typeof styles> {
  accountId: string;
  selectedOption: string;
}

const ClippedDrawer = (props: IProps) => {
  const options: Array<[string, string]> = [
    [SETTINGS_PATH, "Account"],
    [BILLING_PATH, "Billing"],
    [API_KEYS_PATH, "API Keys"],
    [MANAGE_ORGS_PATH, "Organizations"],
  ];

  const [selected, setSelected] = useState(props.selectedOption);

  const { classes, accountId } = props;

  function handleSelect(option: string) {
    setSelected(option);
  }

  return (
    <React.Fragment>
      <Header
        onBack={() => {
          handleSelect("Account");
        }}
      />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List data-testid="settings-list">
          <ListItem>
            <ListItemText
              disableTypography={true}
              primary={<Typography className={`${classes.contrastText} ${classes.title}`}>SETTINGS</Typography>}
            />
          </ListItem>
          {options.map(([path, displayName], key) => {
            return (
              <Link key={key} to={formatRoute(path, { accountId })}>
                <ListItem
                  className={selected === displayName ? classes.activeItem : ""}
                  button={true}
                  selected={selected === displayName}
                  onClick={() => handleSelect(displayName)}
                >
                  <ListItemText
                    disableTypography={true}
                    primary={<Typography className={classes.contrastText}>{displayName}</Typography>}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default withStyles(styles)(ClippedDrawer);
