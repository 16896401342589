import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingAccount, ExistingOrgInvite, NewAccount } from "../generatedApi";
import { accountApi } from "../services/api";

export class AccountStore {
  @observable public account: ExistingAccount = {
    email: "",
    email_verified: false,
    uuid: "",
    name: "",
  };
  @observable public invites: ExistingOrgInvite[] = [];

  @computed public get isSignedIn() {
    return this.account.uuid !== "";
  }

  @action public async loadAccount() {
    try {
      const response = await accountApi.listAccounts();
      this.account = response.data[0];
    } catch (err) {
      throw err;
    }
  }

  @action public async loadInvites() {
    try {
      const response = await accountApi.listPendingInvites(this.account.uuid);
      this.invites = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async createAccount(account: NewAccount) {
    try {
      const response = await accountApi.createAccount(account);
      this.account = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async acceptInvite(inviteId: string) {
    try {
      await accountApi.acceptOrganizationInvite(this.account.uuid, inviteId);
      await this.loadInvites();
    } catch (err) {
      throw err;
    }
  }

  @action public async leaveOrganization(orgId: string) {
    try {
      await accountApi.leaveOrganization(this.account.uuid, orgId);
    } catch (err) {
      throw err;
    }
  }

  @action public clear() {
    this.account = {
      email: "",
      email_verified: false,
      uuid: "",
      name: "",
    };
    this.invites = [];
  }
}

export const accountStore = new AccountStore();
export default createContext(accountStore);
