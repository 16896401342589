import { action, observable } from "mobx";
import { createContext } from "react";
import { sessionApi } from "../services/api";
import history from "../stores/History";

class AppStore {
  @observable public organization: { name: string; uuid: string };

  constructor() {
    const orgName = window.localStorage.getItem("org_name");
    const orgUuid = window.localStorage.getItem("org_uiid");
    this.organization = orgName && orgUuid ? { name: orgName, uuid: orgUuid } : { name: "", uuid: "" };
  }

  @action public setOrganization(name: string, uuid: string) {
    window.localStorage.setItem("org_name", name);
    window.localStorage.setItem("org_uiid", uuid);
    this.organization = { name, uuid };
  }

  @action public logOut() {
    sessionApi.sessionDestroy();
    this.clear();
    history.push("/");
  }

  public clear() {
    window.localStorage.clear();
    this.setOrganization("", "");
  }

  public isLoggedIn() {
    return Boolean(this.organization.uuid);
  }
}

export const appStore = new AppStore();
export default createContext(appStore);
