import { createStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 240;
export const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.secondary.main,
    },
    toolbar: theme.mixins.toolbar,
    activeSettings: {
      color: theme.palette.action.selected,
    },
    contrastText: {
      color: theme.palette.secondary.contrastText,
    },
    title: {
      fontWeight: 500,
    },
    noMargin: {
      marginRight: 0,
    },
    settingsIcon: {
      fontSize: "18px",
    },
    activeItem: {
      backgroundColor: `${theme.palette.primary.light}!important`,
    },
  });
