import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { formatRoute } from "react-router-named-routes";
import { Suite } from "../../generatedApi";
import { checkStatus } from "../../services/api/checkStatus";
import SuitesContext from "../../stores/SuiteStore";
import Header from "../Header/SiteHeader";
import { MANAGE_MEMBERSHIP_PATH, MANAGE_SUITES_PATH, PROJECTS_PATH, SUITE_PATH } from "../Routes";
import { MANAGE_MEMBERSHIP_KEY } from "../Suite/ManageMembership";
import Link from "../UI/Link";
import { styles } from "./styles";

interface IProps extends WithStyles<typeof styles> {
  projectName: string;
  suiteId?: string;
  manage?: boolean;
}

const ClippedDrawer = observer((props: IProps) => {
  const { classes, projectName, suiteId, manage } = props;
  const suitesStore = useContext(SuitesContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        suitesStore.setProject(projectName);
        await suitesStore.loadSuites();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          <Link to={formatRoute(PROJECTS_PATH)}>
            <ListItem button={true}>
              <ListItemText
                disableTypography={true}
                primary={<Typography className={classes.contrastText}>&lt; Back to Projects</Typography>}
              />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List data-testid="suite-list">
          <ListItem>
            <ListItemText
              disableTypography={true}
              primary={<Typography className={`${classes.contrastText} ${classes.title}`}>SUITES</Typography>}
            />
            <Link to={formatRoute(MANAGE_SUITES_PATH, { projectName })}>
              <ListItemIcon
                color="primary"
                className={
                  manage
                    ? `${classes.activeSettings} ${classes.noMargin}`
                    : `${classes.noMargin} ${classes.contrastText}`
                }
                data-testid="settings-icon"
              >
                <SettingsIcon className={classes.settingsIcon} />
              </ListItemIcon>
            </Link>
          </ListItem>
          {suitesStore.suites.map((suite: Suite, index: number) => {
            return (
              <Link
                key={index}
                to={{
                  pathname: formatRoute(SUITE_PATH, { projectName, suiteId: suite.uuid }),
                }}
              >
                <ListItem
                  className={suiteId === suite.uuid ? classes.activeItem : ""}
                  button={true}
                  selected={suiteId === suite.uuid ? true : false}
                >
                  <ListItemText
                    disableTypography={true}
                    primary={<Typography className={classes.contrastText}>{suite.name}</Typography>}
                  />
                </ListItem>
              </Link>
            );
          })}
          <Link key={suitesStore.suites.length} to={formatRoute(MANAGE_MEMBERSHIP_PATH, { projectName })}>
            <ListItem
              className={suiteId === MANAGE_MEMBERSHIP_KEY ? classes.activeItem : ""}
              button={true}
              selected={suiteId === MANAGE_MEMBERSHIP_KEY ? true : false}
            >
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography className={classes.contrastText}>
                    <i>All Tests</i>
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </React.Fragment>
  );
});

export default withStyles(styles)(ClippedDrawer);
