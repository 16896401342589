import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyIcon from "@material-ui/icons/VpnKey";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { styles } from "../Styles/layout";

import { Button, FormControl, Link, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { match } from "react-router";
import { checkStatus } from "../../services/api/checkStatus";
import AccountContext from "../../stores/AccountStore";
import ApiKeysContext from "../../stores/ApiKeysStore";
import Conditional from "../Conditional";
import SettingsSidebar from "../SideBar/SettingsSidebar";
import EmptyState from "../UI/EmptyState";
import { useDialog } from "../UI/SimpleDialog";
import AddApiKey from "./AddApiKey";
import ApiKeyList from "./ApiKeyList";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ accountId: string }>;
}

export const ManageApiKeys = observer((props: IProps) => {
  const [isAddDialogOpen, toggleAddDialog] = useDialog();
  const [loaded, setLoaded] = useState(false);

  const apiKeysStore = useContext(ApiKeysContext);
  const accountStore = useContext(AccountContext);
  const { match, classes } = props;

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        apiKeysStore.setAccount(match.params.accountId);
        await apiKeysStore.loadApiKeys();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      } finally {
        setLoaded(true);
      }
    };
    fetchApiKeys();
  }, [match.params.accountId]);

  function handleAddApiKey() {
    toggleAddDialog();
  }

  const EmptyApiKeys = () => {
    const description = (
      <React.Fragment>
        There aren't any API Keys associated with this account.
        <br />
        Check out our{" "}
        <Link href="https://joylabs.github.io/perf-api/" underline="always">
          {" "}
          documentation
        </Link>
        &nbsp; on{" "}
        <Link href="https://joylabs.github.io/perf-api/#/Account/account_key_create" underline="always">
          {" "}
          how to create one
        </Link>
        .
      </React.Fragment>
    );
    return (
      <EmptyState Icon={KeyIcon} label="API KEYS" description={description}>
        <Button color="primary" variant="contained" onClick={handleAddApiKey}>
          {" "}
          ADD NEW API KEY
        </Button>
      </EmptyState>
    );
  };

  return (
    <div className={classes.root}>
      <SettingsSidebar selectedOption="API Keys" accountId={accountStore.account.uuid} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Conditional if={!loaded}>
          <CircularProgress />
        </Conditional>
        <Conditional if={loaded && apiKeysStore.apiKeys.length === 0}>
          <EmptyApiKeys />
        </Conditional>
        <Conditional if={loaded && apiKeysStore.apiKeys.length > 0}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Button data-testid="api_key_create_button" color="primary" variant="contained" onClick={handleAddApiKey}>
              ADD NEW API KEY
            </Button>
          </FormControl>
          <Typography data-testid="settings_section_title" variant="h5">
            API Keys
          </Typography>
          <br />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.borderRight}>
                  Name
                </TableCell>
                <TableCell align="left">API Key</TableCell>
              </TableRow>
            </TableHead>
            <ApiKeyList apiKeys={apiKeysStore.apiKeys} accountId={accountStore.account.uuid} />
          </Table>
        </Conditional>
      </main>
      <AddApiKey active={isAddDialogOpen} toggleActive={handleAddApiKey} accountId={match.params.accountId} />
    </div>
  );
});

export default withStyles(styles)(ManageApiKeys);
