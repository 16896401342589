import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { dialog as styles } from "../Styles/common";

interface IProps extends WithStyles<typeof styles> {
  active: boolean;
  toggleActive: () => void;
  name: string;
  label?: string;
  onDelete: () => void;
}

const DeleteDialog = (props: IProps) => {
  const [isConfirmed, setConfirmed] = useState(false);

  const { classes, label } = props;

  function handleClose() {
    props.toggleActive();
    setConfirmed(false);
  }

  function handleDelete() {
    props.onDelete();
    handleClose();
  }

  function toggleConfirm() {
    setConfirmed(!isConfirmed);
  }

  return (
    <React.Fragment>
      <Dialog
        classes={{ paperScrollPaper: classes.dialogPaper }}
        open={props.active}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Are you sure you want to delete {props.name} ?</DialogTitle>
        <DialogContent>
          <FormGroup row={true}>
            <FormControlLabel
              data-testid="delete_confirm"
              control={<Checkbox classes={{ root: classes.checkbox }} checked={isConfirmed} onChange={toggleConfirm} />}
              label={
                label
                  ? label
                  : "I understand that deleting this will permanently remove it from the system without the ability to restore it."
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button data-testid="delete_button" disabled={!isConfirmed} onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(DeleteDialog);
