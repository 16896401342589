import { withStyles, WithStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { styles } from "../Styles/layout";
import InviteUser from "./InviteUser";

import { Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { match } from "react-router";
import { ExistingOrgAccount, ExistingOrgInvite2 } from "../../generatedApi";
import { checkStatus } from "../../services/api/checkStatus";
import InvitesContext from "../../stores/InvitesStore";
import MembersContext from "../../stores/MembersStore";
import OrganizationsContext from "../../stores/OrganizationsStore";
import SettingsSidebar from "../SideBar/SettingsSidebar";
import { useDialog } from "../UI/SimpleDialog";
import Snackbar from "../UI/SnackBar";
import InviteListItem from "./InviteListItem";
import MemberListItem from "./MemberListItem";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ orgId: string; accountId: string }>;
}

export const ManageMembers = observer((props: IProps) => {
  const [isDialogOpen, toggleDialog] = useDialog();

  const orgStore = useContext(OrganizationsContext);
  const invitesStore = useContext(InvitesContext);
  const membersStore = useContext(MembersContext);
  const { match, classes } = props;

  const orgId = match.params.orgId;

  useEffect(() => {
    try {
      orgStore.loadOrganization(orgId);
      invitesStore.setOrganization(orgId);
      invitesStore.loadInvites();
      membersStore.setOrganization(orgId);
      membersStore.loadMembers();
    } catch (error) {
      if (error.response) {
        checkStatus(error.response.status);
      }
    }
  }, [match.params.orgId]);

  return (
    <div className={classes.root}>
      <SettingsSidebar selectedOption="Users" accountId={match.params.accountId} />
      <main className={classes.content}>
        <Snackbar />
        <div className={classes.toolbar} />
        <FormControl className={classes.formControl}>
          <Button variant="contained" color="primary" onClick={toggleDialog} data-testid="invite-user-button">
            INVITE NEW USERS
          </Button>
        </FormControl>
        <Typography variant="h5">{orgStore.getOrganization(orgId).name} Members</Typography>
        <br />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.borderRight}>
                NAME
              </TableCell>
              <TableCell align="left">ROLE</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-testid="users-table">
            {invitesStore.invites.map((invite: ExistingOrgInvite2) => {
              return (
                <TableRow key={invite.uuid}>
                  <InviteListItem invite={invite} />
                </TableRow>
              );
            })}
            {membersStore.members.map((member: ExistingOrgAccount) => {
              return (
                <TableRow key={member.uuid}>
                  <MemberListItem org={orgStore.getOrganization(orgId)} member={member} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </main>
      <InviteUser
        dialogActive={isDialogOpen}
        dialogToggle={toggleDialog}
        org={orgStore.getOrganization(orgId)}
        classes={classes}
      />
    </div>
  );
});

export default withStyles(styles)(ManageMembers);
