import { IconButton, Link } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ExistingKey } from "../../generatedApi";
import ApiKeysContext from "../../stores/ApiKeysStore";
import { styles } from "../Styles/layout";
import DeleteDialog from "../UI/DeleteDialog";
import { useDialog } from "../UI/SimpleDialog";

interface IApiKeyItemProps extends WithStyles<typeof styles> {
  apiKey: ExistingKey;
  accountId: string;
}

const ApiKeyListItem = (props: IApiKeyItemProps) => {
  const dudUrl = "javascript:;";
  let timer: any = null;

  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const [label, setLabel] = React.useState("Copy");

  const apiKeysStore = useContext(ApiKeysContext);
  const { apiKey, classes } = props;

  function handleCopy() {
    setLabel("Copied");
    timer = setInterval(() => {
      setLabel("Copy");
      clearInterval(timer);
    }, 400);
  }

  return (
    <React.Fragment>
      <TableCell align="left" className={classes.borderRight}>
        {apiKey.name}
      </TableCell>
      <TableCell align="left" style={{ display: "flex" }}>
        <div style={{ alignSelf: "center" }}>
          {apiKey.value}&nbsp;
          <CopyToClipboard text={apiKey.value} onCopy={handleCopy}>
            <Link color="secondary" href={dudUrl} underline="always" style={{ position: "absolute" }}>
              {label}
            </Link>
          </CopyToClipboard>
        </div>
        <IconButton
          data-testid="api_key_delete_option"
          aria-label="Delete API Key"
          style={{ color: "black", marginLeft: "auto" }}
          onClick={toggleDeleteDialog}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <DeleteDialog
        active={isDeleteDialogOpen}
        toggleActive={toggleDeleteDialog}
        name={apiKey.name}
        onDelete={() => apiKeysStore.deleteApiKey(apiKey.uuid)}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(ApiKeyListItem);
