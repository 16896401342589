import { withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import { styles } from "./Styles/layout";

import ProjectsSideBar from "./SideBar/ProjectsSidebar";
import SuitesOwnedByProjects from "./Suite/SuitesOwnedByProjects";

interface IProps extends WithStyles<typeof styles> {}

function Dashboard(props: IProps) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <ProjectsSideBar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <SuitesOwnedByProjects />
      </main>
    </div>
  );
}

export default withStyles(styles)(Dashboard);
