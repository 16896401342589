import { FormControl, TextField, Typography, WithStyles } from "@material-ui/core";
import React, { ReactNode, useContext, useState } from "react";
import { ExistingOrganization, ForbiddenError } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import OrgContext from "../../stores/OrganizationsStore";
import { styles } from "../Styles/layout";
import SimpleDialog from "../UI/SimpleDialog";

interface IUpdateOrganizationProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  onSuccess: (msg: ReactNode) => void;
  onFailure: (msg: ReactNode) => void;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const UpdateOrganization = (props: IUpdateOrganizationProps) => {
  const [orgName, setOrgName] = useState(props.org.name);

  const orgStore = useContext(OrgContext);

  function closeDialog() {
    props.dialogToggle();
    handleInput("");
  }

  function handleInput(input: string) {
    setOrgName(input);
  }

  async function handleSubmit() {
    try {
      await orgStore.updateOrganization(props.org.uuid, { name: orgName });
      props.onSuccess("Successfully updated organization");
      closeDialog();
    } catch (error) {
      props.onFailure(
        handleResponseError(error, {
          403: (data: ForbiddenError) => {
            return (
              <Typography color="inherit">
                {data.error}: <strong>{props.org.name}</strong>
              </Typography>
            );
          },
        }),
      );
    }
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Update Organization"}
      actionLabel="UPDATE"
      onAction={handleSubmit}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
    >
      <FormControl className={props.classes.form}>
        <TextField
          autoFocus={true}
          value={orgName}
          variant={"outlined"}
          onChange={evt => handleInput(evt.target.value)}
        />
      </FormControl>
    </SimpleDialog>
  );
};

export default UpdateOrganization;
