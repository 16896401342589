/* tslint:disable:no-console */
import { FormControl, InputLabel, MenuItem, Select, Typography, WithStyles } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import React, { ReactNode, useContext, useState } from "react";
import { ExistingOrgAccount, ExistingOrganization, ForbiddenError } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import MemberContext from "../../stores/MembersStore";
import { styles } from "../Styles/layout";
import SimpleDialog from "../UI/SimpleDialog";

interface IUpdateUserProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  member: ExistingOrgAccount;
  onSuccess: (msg: ReactNode, variant: "default" | "error" | "success" | "warning" | "info") => void;
  onFailure: (msg: ReactNode, variant: "default" | "error" | "success" | "warning" | "info") => void;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const UpdateUser = (props: IUpdateUserProps) => {
  const defaultRole: string = "editor";
  const memberStore = useContext(MemberContext);

  const [userRole, setUserRole] = useState(props.member.role);

  function closeDialog() {
    props.dialogToggle();
    handleInput(defaultRole);
  }

  function handleInput(input: string) {
    setUserRole(input);
  }

  async function handleSubmit() {
    try {
      await memberStore.updateMember(props.member.uuid, { role: userRole });
      props.onSuccess(`Successfully updated ${props.member.name}`, "success");
      closeDialog();
    } catch (error) {
      props.onFailure(
        handleResponseError(error, {
          403: (data: ForbiddenError) => {
            return (
              <Typography color="inherit">
                {data.error}: <strong>{props.org.name}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Update user"}
      actionLabel="UPDATE"
      onAction={handleSubmit}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
    >
      <FormControl variant="outlined" margin="dense" required={true} fullWidth={true}>
        <InputLabel htmlFor="outlined-role-select">Role</InputLabel>
        <Select
          value={userRole}
          onChange={evt => handleInput(evt.target.value)}
          input={<OutlinedInput labelWidth={50} name="role" id="outlined-role-select" />}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="editor">Editor</MenuItem>
          <MenuItem value="viewer">Viewer</MenuItem>
        </Select>
      </FormControl>
    </SimpleDialog>
  );
};

export default UpdateUser;
