import { withStyles, WithStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { IconButton, Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ExistingOrgInvite2, ForbiddenError } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import { roleValueToName } from "../../services/helpers/roleValueToName";
import InvitesContext from "../../stores/InvitesStore";
import NotificationContext from "../../stores/NotificationStore";
import { styles } from "../Styles/layout";

interface IMenuProps {
  email: string;
  invite: ExistingOrgInvite2;
}

const InviteMenu = observer((props: IMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const notificationStore = useContext(NotificationContext);
  const invitesStore = useContext(InvitesContext);
  const { email, invite } = props;

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function resendInvite() {
    try {
      await invitesStore.resendInvite(invite.uuid);
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          An invite to <strong>{email}</strong> has been resent.
        </Typography>,
        "success",
      );
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(
        handleResponseError(error, {
          403: (data: ForbiddenError) => {
            return (
              <Typography color="inherit">
                {data.error}: <strong>{invite.email}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  async function cancelInvite() {
    try {
      await invitesStore.deleteInvite(invite.uuid);
      notificationStore.enqueueAutohideSnackbar(
        <Typography color="inherit">
          An invite to <strong>{email}</strong> has been cancelled.
        </Typography>,
        "success",
      );
    } catch (error) {
      notificationStore.enqueueAutohideSnackbar(
        handleResponseError(error, {
          403: (data: ForbiddenError) => {
            return (
              <Typography color="inherit">
                {data.error}: <strong>{invite.email}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  return (
    <React.Fragment>
      <IconButton data-testid="user_invite_menu" aria-label="More" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={resendInvite}>Resend Invite</MenuItem>
        <MenuItem onClick={cancelInvite}>Cancel Invite</MenuItem>
      </Menu>
    </React.Fragment>
  );
});

interface IListItemProps extends WithStyles<typeof styles> {
  invite: ExistingOrgInvite2;
}

const InviteListItem = (props: IListItemProps) => {
  return (
    <React.Fragment>
      <TableCell align="left" className={props.classes.borderRight}>
        {props.invite.email}
      </TableCell>
      <TableCell align="left">
        {roleValueToName(props.invite.role) + " "}
        <span style={{ color: "#ff8d14" }}>
          <i>Pending</i>
        </span>
      </TableCell>
      <TableCell align="right">
        <InviteMenu email={props.invite.email} invite={props.invite} />
      </TableCell>
    </React.Fragment>
  );
};

export default withStyles(styles)(InviteListItem);
