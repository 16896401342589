import { Theme } from "@material-ui/core/styles";
import bg from "../../images/bg_site.jpg";

export const marketing = (theme: Theme) => {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
        backgroundImage: `url(${bg})`,
        backgroundSize: "100%",
      },
      a: {
        color: theme.palette.common.black,
        textDecoration: "none",
        transition: ".3s",
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
  };
};

export const form = (theme: Theme) => {
  return {
    container: {
      padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px`,
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      fontSize: "2.4rem",
      fontWeight: 300,
      textAlign: "center",
      marginBottom: theme.spacing.unit * 7,
    },
    gutter: {
      paddingRight: theme.spacing.unit * 7,
      borderRight: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: {
        border: "none",
        padding: 0,
      },
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      paddingLeft: theme.spacing.unit * 7,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    authField: {
      margin: `${theme.spacing.unit}px 0`,
    },
    progress: {
      margin: "0 auto",
    },
    togglePassword: {
      width: "32px",
      height: "32px",
      position: "absolute",
      right: 0,
      top: "12px",
      padding: 0,
    },
    orWrapper: {
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: `${theme.spacing.unit * 5}px 0 ${theme.spacing.unit * 2}px`,
      },
    },
    or: {
      position: "absolute",
      right: "-75px",
      top: "-165px",
      textAlign: "center",
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing.unit,
      [theme.breakpoints.down("xs")]: {
        position: "static",
        margin: "0 auto -18px;",
        top: theme.spacing.unit * 4,
        width: theme.spacing.unit * 6,
      },
    },
    submit: {
      margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 4}px`,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
      },
    },
    ico: {
      width: 50,
      position: "absolute",
      top: 20,
      left: 20,
    },
  };
};

export const dialog = (theme: Theme) => {
  return {
    checkbox: {
      paddingTop: 0,
    },
    dialogPaper: {
      minWidth: 500,
    },
  };
};
