/* tslint:disable:no-console */
import { Typography, WithStyles } from "@material-ui/core";
import React, { ReactNode, useContext } from "react";
import { ExistingOrgAccount, ExistingOrganization, ForbiddenError } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import MemberContext from "../../stores/MembersStore";
import { styles } from "../Styles/layout";
import SimpleDialog from "../UI/SimpleDialog";

interface IRemoveUserProps extends WithStyles<typeof styles> {
  org: ExistingOrganization;
  member: ExistingOrgAccount;
  onSuccess: (msg: ReactNode, variant: "default" | "error" | "success" | "warning" | "info") => void;
  onFailure: (msg: ReactNode, variant: "default" | "error" | "success" | "warning" | "info") => void;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const RemoveUser = (props: IRemoveUserProps) => {
  const memberStore = useContext(MemberContext);

  async function handleSubmit() {
    try {
      await memberStore.deleteMember(props.member.uuid);
      props.onSuccess(`Successfully removed ${props.member.name}`, "success");
      props.dialogToggle();
    } catch (error) {
      props.onFailure(
        handleResponseError(error, {
          403: (data: ForbiddenError) => {
            return (
              <Typography color="inherit">
                {data.error}: <strong>{props.org.name}</strong>
              </Typography>
            );
          },
        }),
        "error",
      );
    }
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={`Are you sure you want to remove ${props.member.name}?`}
      actionLabel="CONFIRM"
      onAction={handleSubmit}
      actionButtonProps={{ "data-testid": "confirm-dialog-button" }}
      onClose={props.dialogToggle}
      extraProps={{ fullWidth: true }}
    />
  );
};

export default RemoveUser;
