import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Suite } from "../generatedApi";
import { suiteApi } from "../services/api";

export class SuitesStore {
  @observable private suitesRegistry = observable.map<string, Suite>();
  @observable private projectName: string = "";

  @computed get suites() {
    return Array.from(this.suitesRegistry.values());
  }

  @action public setProject(projectName: string) {
    if (this.projectName !== projectName) {
      this.suitesRegistry.clear();
      this.projectName = projectName;
    }
  }

  @action public async loadSuites() {
    try {
      const response = await suiteApi.listSuites(this.projectName);
      this.suitesRegistry.clear();
      response.data.map(suite => {
        if (suite.uuid) {
          this.suitesRegistry.set(suite.uuid, suite);
        }
      });
    } catch (err) {
      throw err;
    }
  }

  @action public async loadSuite(suiteId: string) {
    try {
      const response = await suiteApi.getSuiteById(this.projectName, suiteId);
      if (response.data) {
        this.suitesRegistry.set(suiteId, response.data);
      }
    } catch (err) {
      throw err;
    }
  }

  @action public async createSuite(suite: Suite) {
    try {
      const response = await suiteApi.addSuite(this.projectName, suite);
      if (response.data.uuid) {
        this.suitesRegistry.set(response.data.uuid, response.data);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async deleteSuite(suiteId: string) {
    try {
      await suiteApi.deleteSuite(this.projectName, suiteId);
      this.suitesRegistry.delete(suiteId);
    } catch (error) {
      throw error;
    }
  }

  public getSuite(suiteId: string) {
    const suite = this.suitesRegistry.get(suiteId);
    if (suite) {
      return suite;
    }
    return { name: "" };
  }
}

export const suiteStore = new SuitesStore();
export default createContext(suiteStore);
