import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { DataPoint } from "../generatedApi";
import { projectApi } from "../services/api";

export class TrendStore {
  @observable public loading: boolean = true;
  @observable public dataPoints: DataPoint[] = [];
  @observable public testName: string = "";
  @observable private projectName: string = "";

  @computed public get metrics() {
    const metrics: string[] = [];
    this.dataPoints.forEach((point: DataPoint) => {
      Object.keys(point.measurements).forEach((metricName: string) => {
        if (metrics.indexOf(metricName) === -1) {
          metrics.push(metricName);
        }
      });
    });
    return metrics;
  }

  @computed public get chartsData() {
    const data: object[] = [];
    let dataset: any = {};

    this.dataPoints.forEach((point: DataPoint) => {
      dataset = {};
      dataset.name = point.time;
      Object.keys(point.measurements).forEach((metricName: string) => {
        dataset[metricName] = point.measurements[metricName].value;
      });
      data.push(dataset);
    });

    return data;
  }

  @action public setProject(projectName: string) {
    if (this.projectName !== projectName) {
      this.dataPoints = [];
      this.projectName = projectName;
    }
  }

  @action public async loadTrend(testName: string, from?: string, to?: string) {
    try {
      const response = await projectApi.getTrend(this.projectName, testName, from, to);
      this.testName = response.data.test_name;
      this.dataPoints = response.data.data_points;
    } catch (error) {
      throw error;
    }
  }
}

const trendStore = new TrendStore();
export default createContext(trendStore);
