import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
    },
    toolbar: theme.mixins.toolbar,
    layout: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(500 + theme.spacing.unit * 3 * 2)]: {
        width: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    formControl: {
      float: "right",
      flexDirection: "row",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: "white",
    },
    borderRight: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    floatRight: {
      float: "right",
    },

    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing.unit * 6,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    },
    skipStep: {
      display: "block",
      marginTop: theme.spacing.unit * 4,
      textDecoration: "none",
    },
    emptyStateContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    emptyStateIcon: {
      color: `${theme.palette.grey[300]}`,
      fontSize: "160px",
      margin: 0,
    },
    emptyStateLabel: {
      fontSize: "25px",
      fontWeight: 500,
      lineHeight: "40px",
    },
    emptyStateDescription: {
      margin: "1em 0",
      fontSize: "16px",
      lineHeight: "24px",
    },
  });
