export const IsLocal = "development" === process.env.NODE_ENV ? true : false;
// XXX This seems completely ridiculous, but the docs say we're limited to two environments:
// "The Web and Admin SDKs are configured by directly passing values to their initialization functions.
// For these SDK, you can use a runtime check to select development or production configuration variables."
export const IsDevo = true;

const basePath = IsDevo ? "https://api.devo.bench.joylabs.com/api/v1" : "https://api.staging.bench.joylabs.com/api/v1";

export const ApiConf = {
  apiKey: "",
  username: "",
  password: "",
  accessToken: "",
  basePath: IsLocal ? "http://localhost:8000/api/v1" : basePath,
  baseOptions: { withCredentials: true },
};
