import { ReactNode } from "react";
import { ForbiddenError, SeeOtherName, SeeOtherUuid } from "../../generatedApi/api";
import { checkStatus } from "../api/checkStatus";

type EmptyReporter = () => ReactNode;
type ForbiddenErrorReporter = (error: ForbiddenError) => ReactNode;
type SeeOtherUuidReporter = (error: SeeOtherUuid) => ReactNode;
type SeeOtherNameReporter = (error: SeeOtherName) => ReactNode;
type ErrorReporter = SeeOtherNameReporter | SeeOtherUuidReporter | ForbiddenErrorReporter | EmptyReporter;

export function handleResponseError(
  error: any,
  errorMessages?: {
    [status: number]: ErrorReporter;
  },
): ReactNode {
  let errorMessage: ReactNode = "";
  if (error.response.status) {
    if (error.response.status === 401) {
      checkStatus(401);
    }

    // tslint:disable-next-line: no-console
    console.error(error.response);

    errorMessage =
      errorMessages && errorMessages[error.response.status]
        ? errorMessages[error.response.status](error.response.data)
        : "Unexpected Error!";
  } else if (error.request) {
    errorMessage = "The request was made but no response was received";
  } else {
    errorMessage = "Something happened in setting up the request that triggered an Error";
  }
  return errorMessage;
}
