import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { ExistingOrganization, NewOrganization, UpdateOrganization } from "../generatedApi";
import { organizationApi } from "../services/api";
import { appStore } from "./AppStore";

export class OrganizationsStore {
  @observable private organizationRegistry = observable.map<string, ExistingOrganization>();

  @computed get organizations() {
    return Array.from(this.organizationRegistry.values());
  }

  @action public async loadOrganizations() {
    try {
      const response = await organizationApi.listOrganizations();
      this.organizationRegistry.clear();
      response.data.forEach(org => this.organizationRegistry.set(org.uuid, org));
    } catch (error) {
      throw error;
    }
  }

  @action public async loadOrganization(organizationId: string) {
    try {
      const response = await organizationApi.getOrganizationById(organizationId);
      this.organizationRegistry.set(response.data.uuid, response.data);
    } catch (error) {
      throw error;
    }
  }

  @action public async createOrganization(organization: NewOrganization) {
    try {
      const response = await organizationApi.addOrganization(organization);
      this.organizationRegistry.set(response.data.uuid, response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async deleteOrganization(organizationId: string) {
    try {
      await organizationApi.deleteOrganization(organizationId);
      this.organizationRegistry.delete(organizationId);
    } catch (error) {
      throw error;
    }
  }

  @action public async updateOrganization(organizationId: string, updateOrg: UpdateOrganization) {
    try {
      await organizationApi.updateOrganization(organizationId, updateOrg);
      await this.loadOrganization(organizationId);
    } catch (error) {
      throw error;
    }
  }

  public setActiveOrg(uuid: string) {
    const org = this.organizations.find(org => uuid === org.uuid);
    if (org && org.uuid) {
      appStore.setOrganization(org.name, org.uuid);
    }
  }

  public setDefaultOrg() {
    if (this.organizations.length && this.organizations[0].uuid) {
      appStore.setOrganization(this.organizations[0].name, this.organizations[0].uuid);
    }
  }

  public getOrganization(organizationId: string) {
    const org = this.organizationRegistry.get(organizationId);
    if (org) {
      return org;
    }
    return { uuid: "", name: "", email: "", personal: false };
  }
}

export const organizationsStore = new OrganizationsStore();
export default createContext(organizationsStore);
