import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { match } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { ForbiddenError, Suite } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import NotificationContext from "../../stores/NotificationStore";
import SuitesContext from "../../stores/SuiteStore";
import { SUITE_PATH } from "../Routes";
import ProjectSuitesSideBar from "../SideBar/ProjectSuitesSidebar";
import { styles } from "../Styles/layout";
import AddSuite from "../Suite/AddSuite";
import DeleteDialog from "../UI/DeleteDialog";
import NavLink from "../UI/Link";
import { useDialog } from "../UI/SimpleDialog";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string }>;
}

export const ManageSuites = observer((props: IProps) => {
  const [isDeleteDialogOpen, toggleDeleteDialog] = useDialog();
  const [isAddDialogOpen, toggleAddDialog] = useDialog();
  const [selectedSuite, setSelectedSuite] = useState("");

  const suitesStore = useContext(SuitesContext);
  const notificationStore = useContext(NotificationContext);
  const { classes, match } = props;

  function deleteDialogToggle(selectedSuiteId: string) {
    setSelectedSuite(selectedSuiteId);
    toggleDeleteDialog();
  }

  function SuitesMenu(props: { showDelete: () => void }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event: any) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    function handleDelete() {
      props.showDelete();
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          data-testid="manage_suites_menu"
          aria-label="More"
          aria-owns={open ? "project-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="project-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={handleClose}>Rename</MenuItem>
          <MenuItem data-testid="manage_suites_delete_option" onClick={() => handleDelete()}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AddSuite active={isAddDialogOpen} toggleActive={toggleAddDialog} projectName={match.params.projectName} />
      <ProjectSuitesSideBar projectName={match.params.projectName} manage={true} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Button
          data-testid="manage_suites_create_button"
          onClick={toggleAddDialog}
          variant="contained"
          color="primary"
          className={classes.floatRight}
        >
          Add New Suite
        </Button>
        <Typography variant="h5" paragraph={true}>
          Manage Suites
        </Typography>
        <Table className={classes.table} aria-labelledby="Manage Suites">
          <TableHead>
            <TableRow>
              <TableCell align="left" padding={"default"}>
                <Tooltip title="">
                  <TableSortLabel>SUITES</TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="manage_suites_table">
            {suitesStore.suites.map((suite: Suite, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left">
                    <NavLink
                      to={{
                        pathname: formatRoute(SUITE_PATH, {
                          projectName: match.params.projectName,
                          suiteId: suite.uuid,
                        }),
                      }}
                    >
                      {suite.name}
                    </NavLink>
                  </TableCell>
                  <TableCell align="right">
                    <SuitesMenu showDelete={() => deleteDialogToggle(suite.uuid!)} />
                  </TableCell>
                  <DeleteDialog
                    active={selectedSuite === suite.uuid && isDeleteDialogOpen}
                    toggleActive={() => deleteDialogToggle("")}
                    name={suite.name}
                    onDelete={async () => {
                      try {
                        await suitesStore.deleteSuite(suite.uuid ? suite.uuid : "");
                      } catch (error) {
                        const msg = handleResponseError(error, {
                          403: (data: ForbiddenError) => (
                            <Typography color="inherit">
                              {data.error}: <strong>{suite.name}</strong>
                            </Typography>
                          ),
                        });
                        notificationStore.enqueueAutohideSnackbar(msg, "error");
                      }
                    }}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </main>
    </div>
  );
});

export default withStyles(styles)(ManageSuites);
