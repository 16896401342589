import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React, { useContext, useState } from "react";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import OrgContext from "../../stores/OrganizationsStore";
import SimpleDialog from "../UI/SimpleDialog";

interface IProps {
  dialogActive: boolean;
  dialogToggle: () => void;
}

const AddOrganization = (props: IProps) => {
  const [orgName, setOrgName] = useState({ value: "", error: "" });
  const [orgEmail, setOrgEmail] = useState({ value: "", error: "" });
  const [loading, setLoading] = useState(false);

  const orgStore = useContext(OrgContext);

  function closeDialog() {
    props.dialogToggle();
    setOrgName({ value: "", error: "" });
    setOrgEmail({ value: "", error: "" });
  }

  function handleInput(input: string, field: "orgName" | "orgEmail") {
    if (field === "orgName") {
      setOrgName({ value: input, error: input ? "" : orgName.error });
    } else {
      setOrgEmail({ value: input, error: input ? "" : orgEmail.error });
    }
  }

  async function handleSubmit() {
    setLoading(true);
    const orgNameVal = orgName.value;
    const orgEmailVal = orgEmail.value;

    if (orgNameVal && orgEmailVal) {
      if (!orgEmailVal.match(/.+@.+\..+/i)) {
        setOrgEmail({ ...orgEmail, error: "This field must be a valid email" });
        setLoading(false);
        return;
      }

      try {
        await orgStore.createOrganization({ name: orgNameVal, email: orgEmailVal });
        closeDialog();
      } catch (error) {
        setOrgEmail({
          ...orgEmail,
          error: handleResponseError(error, {
            303: () => "An organization with that email already exists",
          }) as string,
        });
      }
    } else {
      if (!orgNameVal) {
        setOrgName({ ...orgName, error: "The name is required!" });
      }
      if (!orgEmailVal) {
        setOrgEmail({ ...orgEmail, error: "The email is required!" });
      }
    }

    setLoading(false);
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Add New Organization"}
      actionLabel="CREATE"
      onAction={handleSubmit}
      actionButtonProps={{ "data-testid": "confirm-dialog-button", disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
    >
      <FormControl margin="dense" required={true} fullWidth={true}>
        <TextField
          autoFocus={true}
          label="Organization Name"
          multiline={true}
          helperText={orgName.error}
          error={orgName.error !== ""}
          fullWidth={true}
          value={orgName.value}
          onChange={evt => handleInput(evt.target.value, "orgName")}
          data-testid="org-name-input"
          disabled={loading}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="dense" required={true} fullWidth={true}>
        <TextField
          label="Organization Contact Email"
          multiline={true}
          helperText={orgEmail.error}
          error={orgEmail.error !== ""}
          fullWidth={true}
          value={orgEmail.value}
          onChange={evt => handleInput(evt.target.value, "orgEmail")}
          data-testid="org-email-input"
          disabled={loading}
          variant="outlined"
          name="org_email"
        />
      </FormControl>
    </SimpleDialog>
  );
};

export default AddOrganization;
