import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import BarChartIcon from "@material-ui/icons/BarChart";
import { observer } from "mobx-react-lite";
import * as qs from "query-string";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { match } from "react-router";
import { IAppProps } from "../../services/helpers/AppProps";
import { getDateRange, setDateRange } from "../../services/helpers/setDateRange";
import Conditional from "../Conditional";
import ProjectSuitesSideBar from "../SideBar/ProjectSuitesSidebar";
import { styles } from "../Styles/layout";
import Loader from "../UI/Loader";
import SuiteList from "./SuiteList";

/* stores */
import { checkStatus } from "../../services/api/checkStatus";
import RegressionContext from "../../stores/RegressionStore";
import SuiteContext from "../../stores/SuiteStore";
import EmptyState from "../UI/EmptyState";

interface IFilter {
  orderBy: string;
  orderDir: "asc" | "desc";
}

const ProgressStyles = {
  display: "block",
  lineHeight: 1, // Keep the progress centered
  margin: "0 auto",
};

interface IProps extends IAppProps {
  match: match<{ projectName: string; suiteId: string }>;
}

export const Suite = observer((props: IProps) => {
  const suiteStore = useContext(SuiteContext);
  const regressionStore = useContext(RegressionContext);
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState<IFilter>({ orderBy: "name", orderDir: "asc" });
  const [rangeDays, setRangeDays] = useState(-1);
  const { location, match, classes } = props;

  useEffect(() => {
    const params = qs.parse(location.search);

    const fetchData = async () => {
      try {
        suiteStore.setProject(match.params.projectName);
        await suiteStore.loadSuite(match.params.suiteId);
        regressionStore.setProject(match.params.projectName);
        regressionStore.setSuite(match.params.suiteId);
        const filterDates = getDateRange(params.from, params.to, 30);
        await regressionStore.loadRegression(filterDates.dateFrom, filterDates.dateTo);
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      } finally {
        setLoaded(true);
      }
    };

    fetchData();
  }, [location.search, match.params.suiteId]);

  const changeOrder = (orderDir: "asc" | "desc") => {
    setFilter({
      orderDir,
      orderBy: "name",
    });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = parseInt(e.target.value, 10);
    setRangeDays(val);
    setDateRange(val);
  };

  const EmptyRegression = observer(() => {
    const description = (
      <React.Fragment>There are no meaningful regression details yet - keep adding tests results!.</React.Fragment>
    );
    return <EmptyState Icon={BarChartIcon} label="REGRESSION" description={description} />;
  });

  return (
    <div className={classes.root}>
      <ProjectSuitesSideBar projectName={match.params.projectName} suiteId={match.params.suiteId} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Loader loaded={loaded} styles={ProgressStyles}>
          <Conditional if={!Object.keys(regressionStore.regression.results).length}>
            <EmptyRegression />
          </Conditional>
          <Conditional if={Object.keys(regressionStore.regression.results).length}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={rangeDays}
                onChange={handleSelectChange}
                input={<OutlinedInput labelWidth={0} name="get_latest" id="outlined-age-simple" />}
              >
                <MenuItem value={30}>Last 30 Days</MenuItem>
                <MenuItem value={60}>Last 60 Days</MenuItem>
                <MenuItem value={90}>Last 90 Days</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h5">Suite {suiteStore.getSuite(match.params.suiteId).name}</Typography>
            <Typography variant="subtitle1" paragraph={true}>
              Performance Benchmark
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.borderRight}
                    padding={"default"}
                    sortDirection={filter.orderDir}
                  >
                    <Tooltip title="Sort" placement={"bottom-start"} enterDelay={300}>
                      <TableSortLabel
                        active={"name" === filter.orderBy}
                        direction={filter.orderDir}
                        onClick={() => changeOrder("desc" === filter.orderDir ? "asc" : "desc")}
                      >
                        Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">Average</TableCell>
                  <TableCell align="right">Latest</TableCell>
                  <TableCell align="right">Delta</TableCell>
                  <TableCell align="left">Metric</TableCell>
                </TableRow>
              </TableHead>
              <SuiteList
                projectId={match.params.projectName}
                suiteId={match.params.suiteId}
                regression={regressionStore.regression}
                orderDir={filter.orderDir}
              />
            </Table>
          </Conditional>
        </Loader>
      </main>
    </div>
  );
});

export default withStyles(styles)(Suite);
