import { blue, cyan, green, indigo, lightBlue, lime, purple, teal } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import * as qs from "query-string";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { match } from "react-router";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IAppProps } from "../../services/helpers/AppProps";
import { getDateRange, setDateRange } from "../../services/helpers/setDateRange";
import ProjectSuitesSideBar from "../SideBar/ProjectSuitesSidebar";

import { styles } from "../Styles/layout";

/* stores */
import { checkStatus } from "../../services/api/checkStatus";
import TrendContext from "../../stores/TrendStore";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string; suiteId: string; test: string }>;
  location: IAppProps["location"];
}

function SeriesColor(index: number) {
  const colors = [blue[500], green[500], cyan[500], indigo[500], lightBlue[500], lime[500], purple[500], teal[500]];

  return colors[index % colors.length];
}

export const Trend = observer((props: IProps) => {
  const trendStore = useContext(TrendContext);
  const [rangeDays, setRangeDays] = useState(-1);
  const { location, match, classes } = props;

  useEffect(() => {
    const params = qs.parse(location.search);
    const filterDates = getDateRange(params.from, params.to, 30);
    const fetchData = async () => {
      try {
        trendStore.setProject(match.params.projectName);
        await trendStore.loadTrend(decodeURIComponent(match.params.test), filterDates.dateFrom, filterDates.dateTo);
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status, "/404");
        }
      }
    };
    fetchData();
  }, [location.search]);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = parseInt(e.target.value, 10);
    setRangeDays(val);
    setDateRange(val);
  };

  return (
    <div className={classes.root}>
      <ProjectSuitesSideBar projectName={match.params.projectName} suiteId={match.params.suiteId} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={rangeDays}
            onChange={handleSelectChange}
            input={<OutlinedInput labelWidth={0} name="get_latest" />}
          >
            <MenuItem value={30}>Last 30 Days</MenuItem>
            <MenuItem value={60}>Last 60 Days</MenuItem>
            <MenuItem value={90}>Last 90 Days</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h5">{trendStore.testName}</Typography>
        <Typography variant="subtitle1" paragraph={true}>
          Acceptance Tests
        </Typography>
        <ResponsiveContainer className={trendStore.loading ? "" : ""} width="99%" height={320}>
          <LineChart style={{ fontFamily: "sans-serif", fontSize: "14px" }} data={trendStore.chartsData}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <Tooltip />
            <Legend wrapperStyle={{ bottom: "-15px" }} />
            {trendStore.metrics.map((metric: string, index: number) => {
              return <Line key={index} type="monotone" dataKey={metric} stroke={SeriesColor(index)} />;
            })}
          </LineChart>
        </ResponsiveContainer>
      </main>
    </div>
  );
});

export default withStyles(styles)(Trend);
