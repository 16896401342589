import { Divider, List, ListItem, ListItemText, Popover, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import AccountIcon from "@material-ui/icons/AccountCircle";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import firebase from "firebase";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { match, RouteComponentProps, withRouter } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { ExistingOrganization } from "../../generatedApi";
import { checkStatus } from "../../services/api/checkStatus";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import AccountContext from "../../stores/AccountStore";
import AppContext from "../../stores/AppStore";
import NotificationContext from "../../stores/NotificationStore";
import OrgContext from "../../stores/OrganizationsStore";
import ProjectContext from "../../stores/ProjectsStore";
import Conditional from "../Conditional";
import { DASHBOARD_PATH, MANAGE_ORGS_PATH, PROJECTS_PATH, SETTINGS_PATH, SIGN_IN_PATH } from "../Routes";
import Notifier from "../UI/Notifier";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbarTitle: {
      flex: 1,
    },
    appLogo: {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
    sIcon: {
      marginTop: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit}px`,
      color: theme.palette.common.white,
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  onBack?: () => void;
  match: match<{ projectName: string }>;
}

export const SiteHeader = observer((props: IProps) => {
  const { classes, location, history, onBack } = props;
  const appStore = useContext(AppContext);
  const accountStore = useContext(AccountContext);
  const orgStore = useContext(OrgContext);
  const projectStore = useContext(ProjectContext);
  const notificationStore = useContext(NotificationContext);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        await orgStore.loadOrganizations();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchOrganizations();
    if (!accountStore.isSignedIn) {
      const fetchAccount = async () => {
        try {
          await accountStore.loadAccount();
          if (!accountStore.account.email_verified) {
            const action = (key: number) => (
              <Button
                onClick={() => {
                  firebase.auth().currentUser!.sendEmailVerification();
                  notificationStore.removeSnackbar(key);
                }}
                color="inherit"
              >
                {"Resend email"}
              </Button>
            );
            notificationStore.enqueueActionSnackbar(
              <Typography>
                You'll receive a verification email in <strong>{accountStore.account.email}</strong> to confirm your
                account. If you haven't received it within a few minutes, check your spam folder or resend the email.
              </Typography>,
              "info",
              action,
            );
          } else {
            await accountStore.loadInvites();
            if (accountStore.invites.length === 1) {
              const action = (key: number) => (
                <Button
                  onClick={async () => {
                    notificationStore.removeSnackbar(key);
                    try {
                      await accountStore.acceptInvite(accountStore.invites[0].uuid);
                      notificationStore.enqueueAutohideSnackbar("Succesfully joined organization", "success");
                    } catch (error) {
                      notificationStore.enqueueAutohideSnackbar(handleResponseError(error), "error");
                    }
                  }}
                  color="inherit"
                >
                  {"Accept"}
                </Button>
              );
              notificationStore.enqueueActionSnackbar(
                <Typography color="inherit">
                  You've been invited to join the organization, <strong>{accountStore.invites[0].name}</strong>
                </Typography>,
                "info",
                action,
              );
            } else if (accountStore.invites.length > 1) {
              const action = (key: number) => (
                <Button
                  onClick={async () => {
                    notificationStore.removeSnackbar(key);
                    history.push(formatRoute(MANAGE_ORGS_PATH, { accountId: accountStore.account.uuid }));
                  }}
                  color="inherit"
                >
                  {"Manage invitations"}
                </Button>
              );
              notificationStore.enqueueActionSnackbar(
                <Typography color="inherit">
                  You've been invited to join <strong>{accountStore.invites.length}</strong> organizations.
                </Typography>,
                "info",
                action,
              );
            }
          }
        } catch (error) {
          if (error.response) {
            checkStatus(error.response.status);
          }
        }
      };
      fetchAccount();
    }

    if (location.pathname.startsWith("/project/")) {
      const fetchProject = async () => {
        try {
          await projectStore.loadProject(props.match.params.projectName);
          const orgId = projectStore.getProject(props.match.params.projectName).organization_id;
          if (orgId) {
            orgStore.setActiveOrg(orgId);
          }
          // tslint:disable-next-line: no-empty
        } catch (error) {}
      };

      fetchProject();
    }
  }, [appStore.organization.uuid]);

  const signOut = async () => {
    appStore.logOut();
    accountStore.clear();
    notificationStore.clear();
  };

  function handleChangeOrg(uuid: string) {
    orgStore.setActiveOrg(uuid);
    history.push(PROJECTS_PATH);
  }

  function isSettings() {
    return location.pathname.startsWith("/account/");
  }

  const BootstrapInput = withStyles(theme => ({
    root: {
      "label + &": {
        marginTop: theme.spacing.unit * 3,
      },
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: 200,
      whiteSpace: "normal",
      paddingLeft: theme.spacing.unit * 2,
    },
  }))(InputBase);

  const OptionsMenu = observer(() => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event: any) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    return (
      <React.Fragment>
        <Notifier />
        <Tooltip title="Settings">
          <IconButton
            data-testid="header_options_button"
            aria-label="Setting"
            aria-haspopup="true"
            aria-owns={anchorEl ? "options-menu" : undefined}
            onClick={handleClick}
          >
            <AccountIcon className={classes.sIcon} fontSize="large" />
          </IconButton>
        </Tooltip>
        <Popover
          id="options-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "center", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <List>
            <ListItem
              data-testid="header_options_settings_button"
              button={true}
              onClick={() => history.push(formatRoute(SETTINGS_PATH, { accountId: accountStore.account.uuid }))}
            >
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button={true}>
              <ListItemText primary="Help" />
            </ListItem>
            <Divider />
            <ListItem button={true} onClick={signOut}>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </Popover>
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.toolbarTitle}>
            <Conditional if={!isSettings()}>
              <FormControl>
                <Select
                  value={appStore.organization.uuid}
                  onChange={e => handleChangeOrg(e.target.value)}
                  input={<BootstrapInput name="org" />}
                >
                  {orgStore.organizations.map((org: ExistingOrganization) => {
                    return (
                      <MenuItem key={org.uuid} value={org.uuid}>
                        {org.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Conditional>
            <Conditional if={isSettings()}>
              <Button
                onClick={() => {
                  if (onBack) {
                    onBack();
                  }
                  history.push(DASHBOARD_PATH);
                }}
                style={{ color: "white" }}
                data-testid="back-button"
              >
                <BackIcon /> Back to {appStore.organization.name}
              </Button>
            </Conditional>
          </div>
          <Conditional if={appStore.isLoggedIn()}>
            <OptionsMenu />
          </Conditional>
          <Conditional if={!appStore.isLoggedIn()}>
            <Button href={SIGN_IN_PATH} color="secondary" variant="outlined">
              Sign In
            </Button>
          </Conditional>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
});

export default withRouter(withStyles(styles)(SiteHeader));
