import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import Conditional from "../Conditional";

import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import SnackBarContext from "../../stores/SnackBar";

const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  });

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export type variantKeys = "success" | "warning" | "error" | "info";

// tslint:disable-next-line: prettier
export interface ISnackBarProps extends WithStyles<typeof styles> {}

const SnackBar = observer((props: ISnackBarProps) => {
  const snackbar = useContext(SnackBarContext);
  const { classes } = props;
  const Icon: any = variantIcon[snackbar.data.variant];
  return (
    <Conditional if={snackbar.isActive}>
      <SnackbarContent
        className={classNames(classes[snackbar.data.variant], snackbar.data.className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {snackbar.data.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={() => snackbar.close()}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Conditional>
  );
});

export default withStyles(styles)(SnackBar);
