import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { Radio } from "@material-ui/core";
import { TestSuiteMembership } from "../../generatedApi";
import { IAppProps } from "../../services/helpers/AppProps";
import Conditional from "../Conditional";
import { styles } from "../Styles/layout";

interface IManageSuiteItemProps {
  membership: TestSuiteMembership;
  selected?: TestSuiteMembership;
  classes: IAppProps["classes"];
}

const ManageSuiteListItem = observer((props: IManageSuiteItemProps) => {
  const { membership, classes, selected } = props;
  const [isSelected, setSelected] = React.useState(false);

  useEffect(() => {
    if (selected) {
      setSelected(selected && membership.test_name === selected.test_name);
    } else {
      setSelected(false);
    }
  }, [selected]);

  return (
    <React.Fragment>
      <TableCell align="left" className={classes.borderRight} component="th" scope="row" padding="none">
        <Radio checked={isSelected} name="radio-button-demo" />
        {membership.test_name}
      </TableCell>
      <TableCell align="left">
        {membership.suite_names.map((suiteName: string, index: number) => {
          return <Chip key={index} label={suiteName} />;
        })}
        <Conditional if={membership.suite_names.length <= 0}>
          <i>uncategorized</i>
        </Conditional>
      </TableCell>
    </React.Fragment>
  );
});
export default withStyles(styles)(ManageSuiteListItem);
