import { withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { styles } from "../Styles/layout";

export const SuitesOwnedByProjects = observer(() => {
  return <main />;
});

export default withStyles(styles)(SuitesOwnedByProjects);
