import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { Project } from "../../generatedApi";
import { checkStatus } from "../../services/api/checkStatus";
import AppContext from "../../stores/AppStore";
import ProjectsContext from "../../stores/ProjectsStore";
import Header from "../Header/SiteHeader";
import { PROJECT_SUITES_PATH, PROJECTS_PATH } from "../Routes";
import Link from "../UI/Link";
import { styles } from "./styles";

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  currentProject?: string;
}

const ClippedDrawer = observer((props: IProps) => {
  const { classes, currentProject } = props;
  const projects = useContext(ProjectsContext);
  const appStore = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        projects.setOrganization(appStore.organization.uuid);
        await projects.loadProjects();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchData();
  }, [appStore.organization.uuid]);

  return (
    <React.Fragment>
      <Header />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List data-testid="project-list">
          <ListItem>
            <ListItemText
              disableTypography={true}
              primary={<Typography className={`${classes.contrastText} ${classes.title}`}>PROJECTS</Typography>}
            />
            <Link to={formatRoute(PROJECTS_PATH)}>
              <ListItemIcon
                color="primary"
                className={
                  !currentProject
                    ? `${classes.activeSettings} ${classes.noMargin}`
                    : `${classes.noMargin} ${classes.contrastText}`
                }
              >
                <SettingsIcon className={classes.settingsIcon} />
              </ListItemIcon>
            </Link>
          </ListItem>
          {projects.projects.map((project: Project, index: number) => {
            return (
              <Link key={index} to={formatRoute(PROJECT_SUITES_PATH, { projectName: project.name! })}>
                <ListItem
                  className={currentProject === project.name! ? classes.activeItem : ""}
                  selected={currentProject === project.name! ? true : false}
                  button={true}
                >
                  <ListItemText
                    disableTypography={true}
                    primary={<Typography className={classes.contrastText}>{project.display}</Typography>}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>

        {/* <Divider /> */}
      </Drawer>
    </React.Fragment>
  );
});

export default withRouter(withStyles(styles)(ClippedDrawer));
