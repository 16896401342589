import { withStyles, WithStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import ProjectsSideBar from "../SideBar/ProjectsSidebar";
import { styles } from "../Styles/layout";
import ProjectsList from "./List";

interface IProps extends WithStyles<typeof styles> {}

export const Projects = observer((props: IProps) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <ProjectsSideBar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ProjectsList />
      </main>
    </div>
  );
});

export default withStyles(styles)(Projects);
