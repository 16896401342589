import { Button, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ForbiddenError } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import NotificationContext from "../../stores/NotificationStore";
import SuitesContext from "../../stores/SuiteStore";
import { styles } from "../Styles/layout";

interface IProps {
  projectName: string;
  active: boolean;
  toggleActive: () => void;
}

export const AddSuite = (props: IProps) => {
  const [suiteError, setSuiteError] = React.useState(" ");
  const [loading, setLoading] = React.useState(false);

  const suitesStore = useContext(SuitesContext);
  const notificationStore = useContext(NotificationContext);
  const { projectName } = props;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const payload = formData.get("suite") as string;
    if (payload) {
      try {
        const suite = { name: payload };
        await suitesStore.createSuite(suite);
        props.toggleActive();
      } catch (error) {
        setSuiteError(handleResponseError(error, {
          303: () => "Benchmark suite already exists.",
          400: () => "Bad request.",
          401: () => "Unauthorized",
          403: (data: ForbiddenError) => {
            notificationStore.enqueueAutohideSnackbar(
              <Typography color="inherit">
                {data.error}: <strong>{projectName}</strong>
              </Typography>,
              "error",
            );

            props.toggleActive();

            return " ";
          },
          404: () => "Project not found",
          415: () => "Content-Type header is not application/json.",
          422: () => "Input is semantically incorrect.",
        }) as string);
      }
    } else {
      setSuiteError("Error");
    }
    setLoading(false);
  }

  return (
    <Dialog open={props.active} onClose={props.toggleActive} fullWidth={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Create a new Suite</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" required={true} fullWidth={true}>
            <TextField
              data-testid="manage_suites_new_name"
              name="suite"
              label="Suite name"
              helperText={suiteError}
              error={suiteError !== " "}
              disabled={loading}
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggleActive} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button data-testid="manage_suites_new_button" type="submit" color="secondary" disabled={loading}>
            Create Suite
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddSuite);
