import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";

const styles = (theme: any) =>
  createStyles({
    footer: {
      marginTop: theme.spacing.unit * 8,
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: `${theme.spacing.unit * 6}px 0`,
    },
  });

function SiteFooter(props: any) {
  const { classes } = props;

  return (
    <React.Fragment>
      <footer className={classNames(classes.footer)}>
        <Grid container={true} justify="center">
          <Grid item={true}>
            <Typography className={classes.footerLinks} color="textSecondary" variant="body2">
              A&nbsp;
              <Link color="secondary" href="https://joylabs.com" target="_blank">
                Joy&nbsp;Labs
              </Link>
              &nbsp;project
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </React.Fragment>
  );
}

SiteFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SiteFooter);
