import MULink from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { formatRoute } from "react-router-named-routes";
import { checkStatus } from "../../services/api/checkStatus";
import AccountContext from "../../stores/AccountStore";
import ApiKeysContext from "../../stores/ApiKeysStore";
import { API_KEYS_PATH } from "../Routes";
import Link from "../UI/Link";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
  });

interface IProps extends WithStyles<typeof styles> {
  projectName: string;
}

export const Instructions = observer((props: IProps) => {
  const apiKeysStore = useContext(ApiKeysContext);
  const accountStore = useContext(AccountContext);
  const { classes, projectName } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await accountStore.loadAccount();
        apiKeysStore.setAccount(accountStore.account.uuid);
        await apiKeysStore.loadApiKeys();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchData();
  }, [accountStore, apiKeysStore]);

  return (
    <Paper data-testid="project_instructions">
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" paragraph={true}>
          Great job! Now let's create your first test.
        </Typography>
        <Typography gutterBottom={true}>
          1. Navigate to the{" "}
          <strong>
            <Link to={formatRoute(API_KEYS_PATH, { accountId: accountStore.account.uuid })} color="secondary">
              API Key Creation section
            </Link>
          </strong>{" "}
          and create your API Key.
        </Typography>
        <Typography gutterBottom={true}>
          2. Navigate to the{" "}
          <MULink color="secondary" href="https://joylabs.github.io/perf-api/#/Bench/add_bench" target="_blank">
            documentation for <strong>add_bench</strong>
          </MULink>
          .
        </Typography>
        <Typography gutterBottom={true}>
          3. Near the top of the page, change the endpoint to the staging endpoint.
        </Typography>
        <Typography gutterBottom={true}>
          4. Under the docs for <strong>add_bench</strong> choose <em>Try it out</em>.
        </Typography>
        <Typography gutterBottom={true}>
          5. Supply this project_name: <strong>{projectName}</strong> to <strong>add_bench</strong>
        </Typography>
        <Typography gutterBottom={true}>
          6. Choose the appropriate <strong>Content-Type</strong> for your parser (e.g.:{" "}
          <em>application/vnd.criterion-rs.text</em>).
        </Typography>
        <Typography gutterBottom={true}>
          7. Take some sample output and put it in the payload for <strong>add_bench</strong>.
        </Typography>
        <Typography gutterBottom={true}>
          8. Click the <em>Execute</em> button to send the request.
        </Typography>
        <Typography gutterBottom={true}>9. Copy the sample cURL command suggested.</Typography>
        <Typography gutterBottom={true}>
          10. Change the command to take data piped from your benchmark runner, e.g.:
        </Typography>
        <blockquote>
          <em>
            cargo bench | curl -X POST "https://api.staging.bench.joylabs.com/api/v1/project/
            <strong>{projectName}</strong>/bench" -H "accept: application/json" -H "Content-Type:
            application/vnd.criterion-rs.text" -H "X-API-KEY:{" "}
            <strong>{apiKeysStore.apiKeys.length > 0 ? apiKeysStore.apiKeys[0].value : "[X-API-KEY]"}</strong>"
            --data-binary @-
          </em>
        </blockquote>
        <Typography gutterBottom={true}>
          <MULink color="secondary" href="https://joylabs.github.io/perf-api/#/Bench/add_bench" target="_blank">
            For more questions, please read our documentation.
          </MULink>
        </Typography>
      </Paper>
    </Paper>
  );
});

export default withStyles(styles)(Instructions);
