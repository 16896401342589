import { action, observable } from "mobx";
import { createContext } from "react";
import { ExistingOrgAccount, UpdateOrgAccount } from "../generatedApi";
import { organizationApi } from "../services/api";

export class MembersStore {
  @observable public members: ExistingOrgAccount[] = [];
  @observable public organizationId: string = "";

  @action public setOrganization(organizationId: string) {
    if (this.organizationId !== organizationId) {
      this.members = [];
      this.organizationId = organizationId;
    }
  }

  @action public async loadMembers() {
    try {
      const response = await organizationApi.listOrganizationAccounts(this.organizationId);
      this.members = response.data;
    } catch (err) {
      throw err;
    }
  }

  @action public async deleteMember(memberId: string) {
    try {
      await organizationApi.removeOrganizationAccount(this.organizationId, memberId);
    } catch (err) {
      throw err;
    }
    const idx = this.members.findIndex(member => member.uuid === memberId);
    if (idx > -1) {
      this.members.splice(idx, 1);
    }
  }

  @action public async updateMember(memberId: string, updateMember: UpdateOrgAccount) {
    try {
      await organizationApi.updateOrganizationAccount(this.organizationId, memberId, updateMember);
      await this.loadMembers();
    } catch (err) {
      throw err;
    }
  }
}

const membersStore = new MembersStore();
export default createContext(membersStore);
