import { accountStore } from "../../stores/AccountStore";
import { appStore } from "../../stores/AppStore";
import history from "../../stores/History";

export function checkStatus(status: number, notFound?: string) {
  switch (status) {
    case 401:
      appStore.logOut();
      accountStore.clear();
    case 404:
      if (notFound) {
        history.push(notFound);
      }
  }
}
