import { Button, CircularProgress, FormControl, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import firebase from "firebase";
import firebaseui from "firebaseui";
import { observer } from "mobx-react-lite";
import React, { FormEvent, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import Link from "../../components/UI/Link";
import ico from "../../images/icon.svg";
import { sessionApi } from "../../services/api";
import AccountContext from "../../stores/AccountStore";
import SnackbarContext from "../../stores/SnackBar";
import { ON_BOARDING_ORG_PATH, SIGN_IN_PATH } from "../Routes";
import { form, marketing } from "../Styles/common";
import "../Styles/firebase-ui.css";
import Snackbar from "../UI/SnackBar";

const styles = (theme: any) =>
  createStyles({
    ...marketing(theme),
    ...form(theme),
  } as any);

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

export const SignUp = observer((props: IProps) => {
  const snackbarStore = useContext(SnackbarContext);
  const accountStore = useContext(AccountContext);
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  function userExists() {
    snackbarStore.setData({ message: "Account already exists. Redirecting to Sign In...", variant: "warning" });
    setTimeout(() => {
      snackbarStore.close();
      history.push(SIGN_IN_PATH);
    }, 2500);
  }

  async function signUpToken(name: string, token: string) {
    try {
      await accountStore.createAccount({ name, token });
      snackbarStore.setData({ message: "Successfully created account. Signing in...", variant: "success" });
      setTimeout(async () => {
        const res = await sessionApi.sessionCreate({ token }).catch(error => {
          setLoading(false);
          snackbarStore.setData({ message: error.response.statusText, variant: "error" });
        });
        if (res) {
          setLoading(false);
          history.push(ON_BOARDING_ORG_PATH);
        }
      }, 2500);
    } catch (error) {
      if (error.response.status === 303) {
        setLoading(false);
        userExists();
      } else {
        setLoading(false);
        snackbarStore.setData({ message: error.response.statusText, variant: "error" });
      }
    }
  }

  const emailAuth = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    // Create new user
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .catch(error => {
        switch (error.code) {
          case "auth/email-already-in-use":
            userExists();
            break;
          case "auth/invalid-email":
            snackbarStore.setData({ message: "Malformed e-mail", variant: "error" });
            break;
          case "auth/weak-password":
            snackbarStore.setData({ message: "Please input a stronger password", variant: "error" });
            break;
          // This error shouldn't appear...
          default:
            // tslint:disable-next-line: no-console
            console.error("Unrecognized error code: " + error.code);
        }
        setLoading(false);
      });

    if (res) {
      if (!res.user!.emailVerified) {
        res.user!.sendEmailVerification();
      }
      const email = res.user!.email;
      // Update user profile name (in absence of supplied name)
      const name = email!;
      res
        .user!.updateProfile({
          displayName: name,
        })
        .then(
          () => {
            if (res) {
              res
                .user!.getIdToken(true)
                .then(idToken => {
                  signUpToken(name, idToken);
                })
                .catch(error => {
                  snackbarStore.setData({ message: error, variant: "error" });
                  setLoading(false);
                });
            }
          },
          error => {
            setLoading(false);
          },
        );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      // TODO Since `start` takes an `Element` or a tag, query the element
      // and test for it's existence (ask for permission, not forgiveness).
      try {
        ui.start("#firebaseui-signup-container", {
          callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
              setLoading(true);
              const email = authResult.user.email;
              const name = authResult.additionalUserInfo.profile.name || email;
              const domain = email.substring(email.lastIndexOf("@") + 1);

              firebase
                .auth()
                .currentUser!.getIdToken(/* forceRefresh */ true)
                .then(idToken => {
                  signUpToken(name, idToken);
                })
                .catch(error => {
                  // Handle error
                });

              return false;
            },
          },
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.GithubAuthProvider.PROVIDER_ID],
        });
      } catch (e) {
        // This happens after the page redirects, and doesn't need to be handled.
      }
    }, 0);
  }, []);

  return (
    <main>
      <Snackbar />
      <Link to="/">
        <img alt="Nestor" src={ico} className={classes.ico} />
      </Link>
      <Grid container={true} justify="center">
        <Grid item={true} xs={12} sm={12} md={8}>
          <Grid container={true} className={classes.container} spacing={0}>
            <Grid item={true} xs={12}>
              <Typography component="h1" className={classes.title}>
                Create an account
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={6} className={classes.gutter}>
              <div id="firebaseui-signup-container" />
              {loading && <CircularProgress className={classes.progress} />}
              <Typography variant="body1" color="textSecondary" align="center" paragraph={true}>
                Already have an account? <Link to={SIGN_IN_PATH}>Sign in now</Link>
              </Typography>
              <div className={classes.orWrapper}>
                <Typography className={classes.or}>OR</Typography>
              </div>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <form onSubmit={emailAuth} className={classes.form}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-email"
                    label="Email Address"
                    value={values.email}
                    onChange={handleChange("email")}
                    className={classes.authField}
                    disabled={loading}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    id="adornment-password"
                    label="Password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    className={classes.authField}
                    disabled={loading}
                    variant="outlined"
                  />
                  <IconButton
                    aria-label="Toggle password visibility"
                    className={classes.togglePassword}
                    onClick={handleClickShowPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FormControl>
                <FormControl fullWidth={true}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    Sign Up
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
});

export default withStyles(styles)(SignUp);
