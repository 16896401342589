import { withStyles, WithStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { styles } from "../Styles/layout";

import { Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { match } from "react-router";
import { ExistingOrganization, ExistingOrgInvite } from "../../generatedApi";
import { checkStatus } from "../../services/api/checkStatus";
import AccountContext from "../../stores/AccountStore";
import OrgContext from "../../stores/OrganizationsStore";
import SettingsSidebar from "../SideBar/SettingsSidebar";
import { useDialog } from "../UI/SimpleDialog";
import AddOrganization from "./AddOrganization";
import OrganizationListItem from "./OrganizationListItem";
import ReceivedInviteListItem from "./ReceivedInviteListItem";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ accountId: string }>;
}

export const ManageOrganizations = observer((props: IProps) => {
  const [isDialogOpen, toggleDialog] = useDialog();

  const accountStore = useContext(AccountContext);
  const orgStore = useContext(OrgContext);
  const { match, classes } = props;

  useEffect(() => {
    // TODO: implement API query param to filter by account (permissions)
    const fetchAccount = async () => {
      try {
        await accountStore.loadAccount();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    const fetchData = async () => {
      try {
        await accountStore.loadInvites();
        await orgStore.loadOrganizations();
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };

    if (!accountStore.isSignedIn) {
      fetchAccount();
    }

    fetchData();
  }, [match.params.accountId]);

  return (
    <div className={classes.root}>
      <SettingsSidebar selectedOption="Organizations" accountId={match.params.accountId} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <FormControl className={classes.formControl}>
          <Button variant="contained" color="primary" onClick={toggleDialog} data-testid="new-org-button">
            ADD NEW ORGANIZATION
          </Button>
        </FormControl>
        <Typography data-testid="settings_section_title" variant="h5">
          Organizations
        </Typography>
        <br />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.borderRight}>
                NAME
              </TableCell>
              <TableCell align="left">MEMBERS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-testid="org-table">
            {accountStore.invites.map((invite: ExistingOrgInvite, index: number) => {
              return (
                <TableRow key={index}>
                  <ReceivedInviteListItem invite={invite} accountId={match.params.accountId} />
                </TableRow>
              );
            })}
            {orgStore.organizations.map((org: ExistingOrganization, index: number) => {
              return (
                <TableRow key={index}>
                  <OrganizationListItem org={org} accountId={match.params.accountId} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </main>
      <AddOrganization dialogActive={isDialogOpen} dialogToggle={toggleDialog} />
    </div>
  );
});

export default withStyles(styles)(ManageOrganizations);
