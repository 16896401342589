import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import ApiKeysContext from "../../stores/ApiKeysStore";
import { styles } from "../Styles/layout";

interface IProps {
  accountId: string;
  active: boolean;
  toggleActive: () => void;
}

export const AddApiKey = (props: IProps) => {
  const [apiKeyError, setApiKeyError] = React.useState(" ");
  const [loading, setLoading] = React.useState(false);

  const apiKeysStore = useContext(ApiKeysContext);
  const { accountId } = props;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const payload = formData.get("api_key") as string;
    if (payload) {
      try {
        const newKey = { name: payload };
        await apiKeysStore.createApiKey(newKey);
        props.toggleActive();
      } catch (error) {
        setApiKeyError(handleResponseError(error, {
          303: () => "API Key already exists.",
          400: () => "Bad request.",
          401: () => "Unauthorized",
          403: () => "Forbidden",
          404: () => "Account not found",
          415: () => "Content-Type header is not application/json.",
          422: () => "Input is semantically incorrect.",
        }) as string);
      }
    } else {
      setApiKeyError("Error");
    }
    setLoading(false);
  }

  return (
    <Dialog open={props.active} onClose={props.toggleActive} fullWidth={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Add New Api Key</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" required={true} fullWidth={true}>
            <TextField
              name="api_key"
              label="API Key Name"
              multiline={true}
              helperText={apiKeyError}
              error={apiKeyError !== " "}
              data-testid="api_key_new_name"
              disabled={loading}
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggleActive} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button data-testid="api_key_new_button" type="submit" color="secondary" disabled={loading}>
            Generate and Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddApiKey);
