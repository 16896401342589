import { withStyles, WithStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme } from "@material-ui/core/styles";
import React from "react";
import Conditional from "../Conditional";

interface IProps {
  loaded: boolean;
  children: any;
  styles: any;
}

const Loader = (props: IProps) => {
  const styles = (theme: Theme) => ({
    root: props.styles,
  });

  const Progress = (props: WithStyles<typeof styles>) => <CircularProgress className={props.classes.root} />;
  const StyledProgress = withStyles(styles)(Progress);
  return (
    <React.Fragment>
      <Conditional if={props.loaded}>{props.children}</Conditional>
      <Conditional if={!props.loaded}>
        <StyledProgress />
      </Conditional>
    </React.Fragment>
  );
};

export default Loader;
