import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { Suite } from "../../generatedApi";
import { Regression, Result } from "../../stores/RegressionStore";
/* components */
import SuiteListItem from "./SuiteListItem";

interface ISuiteListProps {
  regression: Regression;
  suiteId: Suite["uuid"];
  projectId: string;
  orderDir: "asc" | "desc";
}

const SuiteList = (props: ISuiteListProps) => {
  if (!props.regression) {
    return null;
  }

  return (
    <TableBody data-testid="suite_regressions_table">
      {props.regression.resultsValues(props.orderDir).map((result: Result, index: number) => {
        return (
          <TableRow key={index}>
            <SuiteListItem result={result} suiteId={props.suiteId} projectId={props.projectId} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default SuiteList;
