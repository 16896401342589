import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import { checkStatus } from "../../services/api/checkStatus";
import AppContext from "../../stores/AppStore";
import OrgContext from "../../stores/OrganizationsStore";
import { ON_BOARDING_PROJECT_PATH } from "../Routes";

const styles = (theme: any) =>
  createStyles({
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing.unit * 6,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    layout: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(500 + theme.spacing.unit * 3 * 2)]: {
        width: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    section: {
      display: "block",
      marginTop: theme.spacing.unit * 2,
    },
    formControl: {
      marginTop: theme.spacing.unit * 2,
    },
    progress: {
      margin: "0 auto",
    },
  });

const ErrorBanner = observer((props: any) => {
  return <Typography variant="body1">Error! {props.children}</Typography>;
});

// tslint:disable-next-line: prettier
interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const FirstOrganization = observer((props: IProps) => {
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const appStore = useContext(AppContext);
  const orgStore = useContext(OrgContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;

    if (name && email) {
      try {
        const org = await orgStore.createOrganization({ name, email });
        await appStore.setOrganization(org.name, org.uuid);

        setLoading(false);
        history.push(ON_BOARDING_PROJECT_PATH);
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
          let errorMessage = "";

          switch (error.response.status) {
            case 303:
              errorMessage = "See Other";
          }

          setError(errorMessage);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      setError("Error");
    }
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography variant="h5" align="left" paragraph={true}>
          Welcome!
        </Typography>
        <Typography variant="body2">
          To get started, let's create your first <strong>Organization</strong>. <i>You can change this name later</i>.
        </Typography>

        {loading && <CircularProgress className={classes.progress} />}
        {error && <ErrorBanner>{error}</ErrorBanner>}

        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl margin="normal" fullWidth={true}>
            <TextField
              data-testid="onboarding_org_name"
              name="name"
              id="outlined-name"
              label="Organization Name"
              variant="outlined"
              disabled={loading}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth={true}>
            <TextField
              data-testid="onboarding_org_email"
              name="email"
              id="outlined-email"
              label="Organization Contact Email"
              variant="outlined"
              disabled={loading}
            />
          </FormControl>
          <FormControl fullWidth={true} className={classes.section}>
            <Button
              data-testid="onboarding_create_org_button"
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Create Organization
            </Button>
          </FormControl>
        </form>
      </Paper>
    </main>
  );
});

export default withStyles(styles)(FirstOrganization);
