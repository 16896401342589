import { Divider, List, ListItem, ListItemIcon, ListItemText, Popover, TableBody } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import LayersIcon from "@material-ui/icons/Layers";
import SearchIcon from "@material-ui/icons/Search";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { match } from "react-router";
import { checkStatus } from "../../services/api/checkStatus";
import Conditional from "../Conditional";
import ProjectSuitesSideBar from "../SideBar/ProjectSuitesSidebar";
import { styles } from "../Styles/layout";
import AddSuite from "../Suite/AddSuite";

export const MANAGE_MEMBERSHIP_KEY = "membership";

/* stores */
import { ForbiddenError, Suite, TestSuiteMembership } from "../../generatedApi";
import { handleResponseError } from "../../services/helpers/ErrorHandler";
import TestMembershipsContext from "../../stores/MembershipsStore";
import NotificationContext from "../../stores/NotificationStore";
import SuitesContext from "../../stores/SuiteStore";
import EmptyState from "../UI/EmptyState";
import { useDialog } from "../UI/SimpleDialog";
import ManageMembershipListItem from "./ManageMembershipListItem";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string; suiteId: string }>;
}

export const ManageMembership = observer((props: IProps) => {
  const [loaded, setLoaded] = useState(false);
  const [isAddDialogOpen, toggleAddDialog] = useDialog();
  const [selected, select] = useState<TestSuiteMembership | undefined>();

  const membershipsStore = useContext(TestMembershipsContext);
  const suitesStore = useContext(SuitesContext);
  const notificationStore = useContext(NotificationContext);
  const { match, classes } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        membershipsStore.setProject(match.params.projectName);
        await membershipsStore.loadMemberships();
        suitesStore.setProject(match.params.projectName);
        await suitesStore.loadSuites();
        setLoaded(true);
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  const SuiteManagementMenu = observer((props: { showAddSuite: () => void }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filteredSuites, setFilteredSuites] = useState<Suite[]>(suitesStore.suites);

    function handleClick(event: any) {
      setAnchorEl(event.currentTarget);
    }

    function handleSearch(event: any) {
      const name = event.target.value;
      setFilteredSuites(suitesStore.suites.filter(suite => !name || suite.name.includes(name)));
    }

    function handleClose() {
      setAnchorEl(null);
    }

    function handleAddSuite() {
      props.showAddSuite();
    }

    async function handleDeleteSuite(suite: Suite) {
      if (suite.uuid && selected) {
        try {
          await membershipsStore.deleteMembership(suite.uuid, selected.test_name);
          select(membershipsStore.getMembership(selected.test_name));
        } catch (error) {
          notificationStore.enqueueAutohideSnackbar(
            handleResponseError(error, {
              403: (data: ForbiddenError) => {
                return (
                  <Typography color="inherit">
                    {data.error}: <strong>{suite.name}</strong>
                  </Typography>
                );
              },
            }),
            "error",
          );
        }
      }
    }

    async function handleSelectSuite(suite: Suite) {
      if (selected && !selected.suite_names.includes(suite.name) && suite.uuid) {
        try {
          await membershipsStore.createMembership(suite.uuid, selected.test_name);
          select(membershipsStore.getMembership(selected.test_name));
        } catch (error) {
          notificationStore.enqueueAutohideSnackbar(
            handleResponseError(error, {
              403: (data: ForbiddenError) => {
                return (
                  <Typography color="inherit">
                    {data.error}: <strong>{suite.name}</strong>
                  </Typography>
                );
              },
            }),
            "error",
          );
        }
      }
    }

    return (
      <React.Fragment>
        <Tooltip title="Add Test to Suite">
          <IconButton
            aria-label="Add Test to Suite"
            aria-haspopup="true"
            aria-owns={anchorEl ? "suites-menu" : undefined}
            onClick={handleClick}
          >
            <LayersIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id="suites-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <ListItem>
            <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </ListItem>
          <Divider />
          <List data-testid="associated_suites_list" style={{ maxHeight: 40 * 5, overflow: "auto" }}>
            {filteredSuites.map((suite: Suite, index: number) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => handleSelectSuite(suite)}
                  button={selected ? !selected.suite_names.includes(suite.name) : true}
                >
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <Conditional if={selected ? selected.suite_names.includes(suite.name) : false}>
                      <CheckIcon data-testid="selected_suite" fontSize="small" />
                    </Conditional>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Typography variant="body2" color="primary">
                        {suite.name}
                      </Typography>
                    }
                  />
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <Conditional if={selected ? selected.suite_names.includes(suite.name) : false}>
                      <IconButton
                        data-testid="remove_suite_button"
                        disableRipple={true}
                        onClick={() => handleDeleteSuite(suite)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Conditional>
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <ListItem onClick={handleAddSuite} button={true}>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography variant="body2" color="primary">
                  Add a new suite
                </Typography>
              }
            />
          </ListItem>
        </Popover>
      </React.Fragment>
    );
  });

  const EmptyTests = observer(() => {
    const description = <React.Fragment>There are no test results in the project.</React.Fragment>;
    return (
      <EmptyState Icon={InsertChartIcon} label="All Tests" description={description}>
        {null}
      </EmptyState>
    );
  });

  return (
    <div className={classes.root}>
      <AddSuite active={isAddDialogOpen} toggleActive={toggleAddDialog} projectName={match.params.projectName} />
      <ProjectSuitesSideBar projectName={match.params.projectName} suiteId={MANAGE_MEMBERSHIP_KEY} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Conditional if={!loaded}>
          <CircularProgress />
        </Conditional>
        <Conditional if={loaded && membershipsStore.memberships.length === 0}>
          <EmptyTests />
        </Conditional>
        <Conditional if={loaded && membershipsStore.memberships.length > 0}>
          <FormControl variant="outlined" className={classes.formControl}>
            <div>
              <Conditional if={Boolean(selected)}>
                <div data-testid="suite_management_menu">
                  <SuiteManagementMenu showAddSuite={toggleAddDialog} />
                  <Tooltip title="Delete Test">
                    <IconButton aria-label="Delete Test">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Conditional>
            </div>
            <Select value={0} input={<OutlinedInput labelWidth={0} name="get_latest" id="outlined-age-simple" />}>
              <MenuItem value={0}>Newest to Oldest</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h5">All Tests</Typography>
          <Typography variant="subtitle1" paragraph={true}>
            &nbsp;
          </Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.borderRight}>Test</TableCell>
                <TableCell align="left">Suites</TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="test_membership_table">
              {membershipsStore.memberships.map((membership: TestSuiteMembership) => {
                return (
                  <TableRow
                    key={membership.test_name}
                    hover={true}
                    onClick={() => {
                      select(membershipsStore.getMembership(membership.test_name));
                    }}
                  >
                    <ManageMembershipListItem selected={selected} membership={membership} />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Conditional>
      </main>
    </div>
  );
});

export default withStyles(styles)(ManageMembership);
