import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { match, RouteComponentProps } from "react-router";
import { checkStatus } from "../../services/api/checkStatus";
import TestMembershipsContext from "../../stores/MembershipsStore";
import Conditional from "../Conditional";
import Instructions from "../Projects/Instructions";
import ProjectsSideBar from "../SideBar/ProjectsSidebar";
import ProjectSuitesSideBar from "../SideBar/ProjectSuitesSidebar";
import { styles } from "../Styles/layout";

interface IProps extends RouteComponentProps, WithStyles<typeof styles> {
  match: match<{ projectName: string }>;
}

export const SuitesOwnedByProject = observer((props: IProps) => {
  const membershipsStore = useContext(TestMembershipsContext);
  const [loaded, setLoaded] = useState(false);
  const { classes, match, location } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        membershipsStore.setProject(match.params.projectName);
        await membershipsStore.loadMemberships();
        setLoaded(true);
      } catch (error) {
        if (error.response) {
          checkStatus(error.response.status);
        }
      }
    };
    fetchData();
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <Conditional if={!loaded || !membershipsStore.memberships.length}>
        <ProjectsSideBar currentProject={match.params.projectName} />
      </Conditional>
      <Conditional if={loaded && membershipsStore.memberships.length}>
        <ProjectSuitesSideBar projectName={match.params.projectName} />
      </Conditional>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Conditional if={!loaded}>
          <CircularProgress />
        </Conditional>
        <Conditional if={loaded && !membershipsStore.memberships.length}>
          <Instructions projectName={match.params.projectName} />
        </Conditional>
        <Conditional if={loaded && membershipsStore.memberships.length}>
          <Typography variant="h5">Project: {match.params.projectName}</Typography>
          <Typography variant="subtitle1" paragraph={true}>
            Acceptance Tests
          </Typography>
        </Conditional>
      </main>
    </div>
  );
});

export default withStyles(styles)(SuitesOwnedByProject);
