import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect } from "react";
import { IAppProps } from "../../services/helpers/AppProps";
import { styles } from "../Styles/layout";

import { checkStatus } from "../../services/api/checkStatus";
import AccountContext from "../../stores/AccountStore";
import SettingsSidebar from "../SideBar/SettingsSidebar";

function AccountSettings(props: IAppProps) {
  const { classes } = props;
  const accountStore = useContext(AccountContext);

  useEffect(() => {
    const fetchAccount = async () => {
      if (!accountStore.isSignedIn) {
        try {
          await accountStore.loadAccount();
        } catch (error) {
          if (error.response) {
            checkStatus(error.response.status);
          }
        }
      }
    };
    fetchAccount();
  });

  return (
    <div className={classes.root}>
      <SettingsSidebar selectedOption="Account" accountId={accountStore.account.uuid} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Typography data-testid="settings_section_title" variant="h5">
          Account
        </Typography>
      </main>
    </div>
  );
}

export default withStyles(styles)(AccountSettings);
